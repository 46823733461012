<template>
  <v-container class="pa-0">
    <v-row class="no-gutters elevation-0">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="custom-chat-height-wrapper">
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="chat-header">
              <label>
                <span>Car History Report</span>
                <small>Vin No: {{ carHistoryReport.vin_number }}</small>
              </label>
              <v-spacer></v-spacer>
              <v-btn
                medium
                light
                min-height="48"
                class="green darken-3 white--text"
                elevation="0"
                @click="dialog=true"
              >
                <v-icon>mdi-email</v-icon>
              </v-btn>
              <v-btn
                :href="carHistoryReport.pdf_path"
                target="_blank"
                medium
                light
                min-height="48"
                class="green darken-3 white--text ml-4"
                elevation="0"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text
              class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"
            >
              <div class="pa-4">
                <vue-pdf-app :pdf="carHistoryReport.pdf_path"></vue-pdf-app>
              </div>
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="12"
              >
                <v-text-field
                  v-model="email"
                  :label="$t('carHistoryReport.enterEmail')"
                ></v-text-field>
              </v-col>
            </v-row>
            <ApiErrorMessage :message="apiErrorMessage"/>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-4 green-gradient white--text"
            text
            @click="dialog=false"
          >
            {{ $t('carHistoryReport.cancel') }}
          </v-btn>
          <v-btn
            color="green darken-4 green-gradient white--text"
            text
            @click="sendHistoryReport"
          >
            {{ $t('carHistoryReport.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import {mapGetters, mapActions} from "vuex";

export default {
  components: {
    VuePdfApp,
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      dialog: false,
      email: null,
      apiErrorMessage: null,
    };
  },

  computed: {
    ...mapGetters({
      carHistoryReport: "carHistory/getCarHistoryReport",
    })
  },

  methods: {
    ...mapActions({
      sendHistoryReportMail: "carHistoryReport/sendHistoryReportMail"
    }),

    close() {
      this.email = null;
      this.dialog = false;
    },

    async sendHistoryReport() {
      this.$store.commit("setLoader", true);

      try {
        await this.sendHistoryReportMail({
          vehicle_history_id: this.carHistoryReport.id,
          email: this.email,
        });
        this.close();
      } catch ({message}) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
  }
};
</script>
